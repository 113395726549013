import React from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import routes, { RenderRoutes } from "./routes";
import { AuthProvider } from "./contexts/JWTAuthContext";

function App() {
  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          <RenderRoutes routes={routes} />
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
