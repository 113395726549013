import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  statusCreateSuperAdmin: "idle",
  successMessageCreateSuperAdmin: null,
  error: null,
  statusValidatEmail: "idle",
  user: {},
  website: {},
  statusCreateWebsite: "idle",
  errorCreateWebsite: null,
};

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  let data;
  try {
    const response = await axios.get(`/client/me`);
    data = response.data.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const createSuperAdmin = createAsyncThunk(
  "website/createSuperAdmin",
  async (body) => {
    const { phone, ...rest } = body;
    const phoneNumber = phone.replace(/^\+\d+\s/, "");
    const newBody = { ...rest, phone: phoneNumber.replace(/\s/g, "") };
    let data;
    try {
      const response = await axios.post(`/client/sign-up`, newBody);
      data = response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message);
    }
  }
);
export const createWebsite = createAsyncThunk(
  "website/createWebsite",
  async (body) => {
    let data;
    try {
      const response = await axios.post(`/clients/websites`, body);
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message);
    }
  }
);

export const validateEmail = createAsyncThunk(
  "website/validateEmail",
  async (token) => {
    let data;
    try {
      const response = await axios.put(`/client/validate-email/${token}`);
      data = response.data?.data;
      if (response.status === 200) {
        const { token } = data;
        if (token !== null) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          localStorage.setItem("token", token);
        } else {
          localStorage.removeItem("token");
          delete axios.defaults.headers.common.Authorization;
        }
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message);
    }
  }
);
const slice = createSlice({
  name: "website",
  initialState,
  reducers: {},
  extraReducers: {
    [createSuperAdmin.pending]: (state) => {
      state.statusCreateSuperAdmin = "loading";
    },
    [createSuperAdmin.fulfilled]: (state, action) => {
      state.statusCreateSuperAdmin = "success";
      state.successMessageCreateSuperAdmin = action.payload.message;
      state.error && (state.error = null);
    },
    [createSuperAdmin.rejected]: (state, action) => {
      state.statusCreateSuperAdmin = "failed";
      state.error = action.error.message;
      state.successMessageCreateSuperAdmin &&
        (state.successMessageCreateSuperAdmin = null);
    },
    [createWebsite.pending]: (state) => {
      state.statusCreateWebsite = "loading";
    },
    [createWebsite.fulfilled]: (state, action) => {
      state.statusCreateWebsite = "success";
      state.website = action.payload;
    },
    [createWebsite.rejected]: (state, action) => {
      state.statusCreateWebsite = "failed";
      state.errorCreateWebsite = action.error.message;
    },
    [validateEmail.pending]: (state) => {
      state.statusValidatEmail = "loading";
    },
    [validateEmail.fulfilled]: (state, action) => {
      state.statusValidatEmail = "success";
      state.user = action.payload.user;
    },
    [validateEmail.rejected]: (state, action) => {
      state.statusValidatEmail = "failed";
      state.error = action.error.message;
    },
    [fetchUser.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = "success";
      state.user = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});
export const {} = slice.actions;
export const reducer = slice.reducer;
export default slice;
