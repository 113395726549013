import { combineReducers } from "@reduxjs/toolkit";
import { reducer as websiteReducer } from "./../slices/website";

const combinedReducer = combineReducers({
  website: websiteReducer,
});
const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};
export default rootReducer;
