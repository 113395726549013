import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useLocation } from "react-router-dom";

const steps = ["", "", ""];

export default function HorizontalLinearStepper({ children }) {
  const { pathname } = useLocation();

  return (
    <Box sx={{ width: "85%", margin: "30px auto 0 auto" }}>
      <Stepper
        activeStep={
          pathname?.split("/").length === 3
            ? pathname.split("/")[1]?.split("-")[2]
            : pathname?.split("-")[2]
        }
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {children}
    </Box>
  );
}
