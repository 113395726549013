import { Suspense, Fragment, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import GuestGuard from "./components/GuestGuard";
import AuthGuard from "./components/AuthGuard";

export const RenderRoutes = ({ routes = [] }) => (
  <Suspense fallback={<>Loading...</>}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;
        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.routes ? (
                    <RenderRoutes routes={route.routes} />
                  ) : (
                    <Element />
                  )}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    path: "/*",
    layout: MainLayout,
    routes: [
      {
       // guard: GuestGuard,
        path: "/active-step-1",
        element: lazy(() => import("./pages/Step1")),
      },

      {
      //  guard: AuthGuard,
        path: "/active-step-2",
        element: lazy(() => import("./pages/Step2")),
      },

      {
        guard: AuthGuard,
        path: "/active-step-3",
        element: lazy(() => import("./pages/Step3")),
      },
      {
        path: "/",
        element: () => <Navigate to="/active-step-1" />,
      },
      // {
      //   path: "*",
      //   element: () => <Navigate to="/active-step-1" />,
      // },
    ],
  },
  {
    // guard: AuthGuard,
    path: "/confirm-account/:token",
    element: lazy(() => import("./pages/VerifyAccount")),
  },
];

export default routes;
