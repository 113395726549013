import React from "react";
import { Navigate } from "react-router-dom";
const AuthGuard = ({ children }) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/active-step-1" />;
  }
  return <>{children}</>;
};

export default AuthGuard;
